import { render, staticRenderFns } from "./RegRepBox.vue?vue&type=template&id=2569adac&scoped=true&"
import script from "./RegRepBox.vue?vue&type=script&lang=ts&"
export * from "./RegRepBox.vue?vue&type=script&lang=ts&"
import style0 from "./RegRepBox.vue?vue&type=style&index=0&id=2569adac&lang=scss&scoped=true&"
import style1 from "./RegRepBox.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2569adac",
  null
  
)

export default component.exports